.successfulDialog {
  .dialogContainer {
    max-width: 375px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .dialogCloseButton {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  .mobileWaveIcon {
    width: 100%;
    object-fit: cover;
  }

  .successTitle {
    color: #fff;
    position: absolute;
    top: 8%;
    text-align: center;
    margin-right: 10px;
    margin-left: 10px;
  }

  .successIconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 24px;
    margin-bottom: 40px;
  }

  .success {
    height: 97px;
    width: 80px;
    object-fit: contain;
    align-self: center;
  }

  .successInfo {
    color: #4a4a4a;
    font-size: 14px;
    text-align: center;
    margin-top: 15px;
    width: 270px;
  }

  .successButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
}
